import './App.css';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

// Components
import Header from './components/Header';
import Books from './components/Books';
import AddBook from './components/AddBook';
// Hooks
import { useEffect, useState } from 'react';
// Packages
import { v4 as uuidv4 } from 'uuid';
import { CircularProgress, IconButton } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';

function App() {
  const defaultBooks: Array<{ id: string, title: string}> = []
  // All States
  const [books, setBooks] = useState(defaultBooks); // Books State
  const [showAddBook, setShowAddBook] = useState(false); // To reveal add book form
  const [loading, setloading] = useState(true); // Pre-loader before page renders
  // Pre-loader
  useEffect(() => {
    setTimeout(() => {
      setloading(false); 
    }, 3500);
  }, []);
  // Fetching from Local Storage
  const getBooks = localStorage.getItem("books");
  const parsedBooks =  getBooks ? JSON.parse(getBooks) : null;
  useEffect(() => {
    if (getBooks == null) {
      setBooks([])
    } else {
      setBooks(parsedBooks);
    }
  }, [])
  // Add Book
  const addBook = (book: { title: string }) => {
    const id = uuidv4();
    const newBook = { id, ...book }
    setBooks([...books, newBook]);
    // TODO: Alert with text : 'You have successfully added a new task!'
    localStorage.setItem("books", JSON.stringify([...books, newBook]));
    setShowAddBook(false);
  }
  // Delete Book
  const deleteBook = (id: string) => {
    const updatedBooks = books.filter((book) => book.id !== id);
    setBooks(updatedBooks);
    // TODO: Alert with text : 'You have successfully deleted a task!'
    localStorage.setItem("books", JSON.stringify(updatedBooks));
  }
  // Edit Book
  const editBook = (id: string) => {
    const oldTitle = books.find(book => book.id === id)?.title ?? '';
    const title = prompt("Book Title", oldTitle);
    if (title) {
      const editedBooks = books.map(book => {
        if (book.id === id) {
          return {
              ...book,
              title: title,
              id: uuidv4()
          }
        }
        return book;
      })
      setBooks(editedBooks);
      // TODO: Alert with text : 'You have successfully edited an existing task!'
      localStorage.setItem("books", JSON.stringify(editedBooks));
    }
  }
  // Theme
  const theme = createTheme({
    palette: {
      primary: {
        // Purple and green play nicely together.
        main: '#0051ba',
      },
      secondary: {
        // This is green.A700 as hex.
        main: '#FFDA1A',
      },
    },
  });
  return (
    <>
    <ThemeProvider theme={theme}>
    {
      loading ?
      
      <div className="spinner-container">
        <CircularProgress color="secondary" size="5rem" thickness={5}/>
      </div>
      :
        <div className="container">
          {/* App Header */}
          <Header />
          <main>
            {/* Books Counter */}
            <h3>Number of Books: {books.length}</h3>
            {/* Revealing the Add Task Form */}
            {<AddBook open={showAddBook} onSave={addBook} />}
            {/* Displaying Tasks */}
            {
              books.length > 0 ?
                (<Books books={books} onDelete={deleteBook} onEdit={editBook}/>) :
                ('No Book Found!')
            }
            <IconButton aria-label="add book" onClick={() => setShowAddBook(!showAddBook)} color="primary" className="fab">
              <AddCircle color="secondary" className="add-icon"/>
            </IconButton>
          </main>
        </div>
    }
    </ThemeProvider>
    </>
  );

}

export default App;
