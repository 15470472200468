import { Avatar, createStyles, IconButton, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, makeStyles, Theme } from '@material-ui/core';
import { DeleteForever, Edit, MenuBook } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    yellow: {
      backgroundColor: '#FFDA1A',
    },
    red: {
      color: 'red'
    }
  }),
);

type BookProps = {
  book: { id: string, title: string},
  onDelete: (id: string) => void,
  onEdit: (id: string) => void
}

const Book = ({ book, onDelete, onEdit }: BookProps) => {
  const classes = useStyles();
  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar className={classes.yellow}>
          <MenuBook color="primary"/>
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={book.title} />
      <ListItemSecondaryAction>
        <IconButton edge="end" aria-label="delete" onClick={() => onEdit(book.id)}>
          <Edit color="secondary"/>
        </IconButton>
        <IconButton edge="end" aria-label="delete" onClick={() => onDelete(book.id)}>
          <DeleteForever className={classes.red}/>
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  )
}
export default Book;