import { List } from '@material-ui/core';
import Book from './Book';

type BooksProps = {
  books: { id: string, title: string}[],
  onDelete: (id: string) => void,
  onEdit: (id: string) => void
}

const Books = ({ books, onDelete, onEdit }: BooksProps) => {
  return (
    <>
    <List>
      {
        books.map((book) => (
          <Book key={book.id} book={book} onDelete={onDelete} onEdit={onEdit} />
        ))
      }
    </List>
    </>
    )
}
export default Books;