import { Button, Dialog, DialogActions, DialogContent, TextField } from '@material-ui/core';
import { FormEvent, useState } from 'react';
import AlertDialog from './AlertDialog';

type AddBookProps = {
  open: boolean
  onSave: (book: { title: string }) => void
}

const AddBook = ({open, onSave}: AddBookProps) => {
  const [title, setTitle] = useState('');
  const [warning, setWarning] = useState(false);
  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!title) {
        setWarning(true);
    } else {
        onSave({ title });
    }
    setTitle('');
  }
  const handleClose = () => {
    setWarning(false);
  }
  return (
    <Dialog open={open} aria-describedby="alert-dialog-description">
      <DialogContent>
        <form id="add-form" className="add-form" onSubmit={onSubmit}>
        <TextField id="standard-basic" label="Add book title" value={title} onChange={(e) => setTitle(e.target.value)}/>
        
        <AlertDialog open={warning} onClose={handleClose}/>
      </form>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" type="submit" form="add-form">Save Book</Button>
      </DialogActions>
    </Dialog>
    )
}
export default AddBook

